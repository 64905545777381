<template>
	<!-- 媒体文件展示 -->
	<div class="media-list d-flex d-flex-start">
		<!-- 图片 -->
		<template v-if="fileDataCopy.imgList.length > 0">
			<div class="media-item d-flex d-flex-center" :style="sizeStyle" v-for="(item, index) in fileDataCopy.imgList" :key="item">
				<div class="item-inner d-flex d-flex-center" :style="sizeStyle">
					<el-image :style="sizeStyle" :preview-src-list="fileDataCopy.imgList" :src="item"></el-image>
				</div>
			</div>
		</template>
		<!-- doc, xls, txt, pdf -->
		<template v-if="fileDataCopy.attachList.length > 0">
			<div class="media-item attach-item d-flex d-flex-center" :style="sizeStyle" v-for="(item, index) in fileDataCopy.attachList" :key="item.src">
				<div class="item-inner d-flex d-flex-center" :style="sizeStyle">
					<a target="_blank" :href="item.src" class="d-flex d-flex-center inner-wrap">
						<span class="attach-nm">{{ item.nm }}</span>
						<span class="preview-txt">点击下载</span>
					</a>
				</div>
			</div>
		</template>
		<!-- 音频 -->
		<template v-if="fileDataCopy.audioList.length > 0">
			<div class="media-item audio-item d-flex d-flex-center" :style="sizeStyle" v-for="(item, index) in fileDataCopy.audioList" :key="item.src">
				<div class="item-inner d-flex d-flex-center" :style="sizeStyle">
					<div class="d-flex d-flex-center inner-wrap" @click="previewRecording(index)">
						<div class="audio-nm">{{ item.nm }}</div>
						<div class="preview-txt">点击查看</div>
					</div>
				</div>
			</div>
		</template>
		<!-- 音频预览 -->
		<el-dialog title="音频预览" :visible.sync="previewAudio.show" width="400px" :close-on-click-modal="false" @closed="closeAudioPreview">
			<div>
				<audio ref="curRecording" :src="previewAudio.src" preload="metadata" controls style="width: 100%;"></audio>
			</div>
		</el-dialog>
	</div>
</template>

<script>

export default {
	name: 'media-exhi',
	props: {
		fileData: {
			type: String,
			required: true
		},
		size: {
			type: String,
			default: '80px'
		}
	},
	data() {
		return {
			// 音频预览流程 - 预览音频弹窗
			previewAudio: {
				show: false,
				src: ''
			},
			fileTp: {
				audio: ['mp3'],
				attach: ['xls', 'xlsx', 'doc', 'docx', 'pdf', 'txt']
			}
		}
	},
	computed: {
		sizeStyle() {
			return `width: ${ this.size }; height: ${ this.size };`
		},
		fileDataCopy() {
			let fd = this.fileData.split(',')
			let temp = []
			let result = {
				audioList: [],
				attachList: [],
				imgList: []
			}
			// 重新包装
			fd.forEach(v => {
				temp.push({
					// 路径
					src: this.imageSrc() + v,
					// 文件格式
					fileNm: this.postfix(v)
				})
			})
			// 分类
			temp.forEach(v => {
				if (this.fileTp.audio.indexOf(v.fileNm) != -1) {
					result.audioList.push({
						nm: `录音`,
						src: v.src
					})
				} else if (this.fileTp.attach.indexOf(v.fileNm) != -1) {
					result.attachList.push({
						nm: `附件`,
						src: v.src
					})
				} else {
					result.imgList.push(v.src)
				}
			})
			return result
		}
	},
	methods: {
		// 打开各种弹窗
		openPopup(nm) {
			this[nm].show = true
		},
		// 关闭各种弹窗
		closePopup(nm) {
			this[nm].show = false
		},
		// 判断后缀名
		postfix(src) {
			let fileTpNm = src.lastIndexOf(".") + 1
			return src.substring(fileTpNm)
		},
		// 预览音频
		previewRecording(idx) {
			this.previewAudio.src = this.fileDataCopy.audioList[idx].src
			this.openPopup('previewAudio')
		},
		// 关闭预览界面
		closeAudioPreview() {
			this.$refs.curRecording.pause()
			this.$refs.curRecording.currentTime = 0
			this.closePopup('previewAudio')
		}
	}
}
</script>

<style scoped lang="scss">
.media-list {
	width: 100%;
	.media-item {
		margin-right: 10px;
		.item-inner {
			overflow: hidden;
			border-radius: 5px;
			image {
				width: 100%;
				height: 100%;
				border-radius: 5px;
			}
		}
	}
	.audio-item, .attach-item {
		cursor: pointer;
		.item-inner {
			border: 1px solid #d9d9d9;
			.inner-wrap {
				flex-direction: column;
			}
			.attach-nm, .audio-nm {
				line-height: 1;
				color: #333;
				margin-bottom: 10px;
			}
			.preview-txt {
				line-height: 1;
				font-size: 12px;
				color: #CCC;
			}
		}
	}
}
</style>