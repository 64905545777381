import {post} from '@/utils/axios'

export const getIndex = (params) => post('/shida-user-biz/TbDic/getIndexByParentId',params)

export const getIndexThree = (params) => post('/shida-user-biz/TbIndexType/getRealisticIndexByTwoIndexId',params)

export const addRecord = (params) => post('/shida-user-biz/TbRealisticRecord/saveOrUpdate',params)

export const getAllSemester = (params) => post('/shida-user-biz/TbTerm/getAllStartTbTerm',params)

export const getTeacherClass = (params) => post('/shida-user-center/SysDept/getCurTeacherMangerClass',params)

export const getPerRecord = (params) => post('/shida-user-biz/TbRealisticRecord/getData',params)

export const updateStatus = (params) => post('/shida-user-biz/TbRealisticRecord/updateAuditStatus',params)

export const getDianZan = (params) => post('/shida-user-biz/TbRealisticRecord/dianZan',params)

export const addComments = (params) => post('/shida-user-biz/TbRealisticRecord/addComments',params)

export const getComments = (params) => post('/shida-user-biz/TbRealisticRecord/getComments',params)

export const removeComments = params => post('/shida-user-biz/TbRealisticRecord/removeComments', params)


// 我的发布
export const getMyrease = (params) => post('/shida-user-biz/TbRealisticRecord/getOwnData',params)

export const removeMyrease = (params) => post('/shida-user-biz/TbRealisticRecord/remove',params)

// 质疑 - 添加/删除
export const updateQuesNum = params => post('/shida-user-biz/TbRealisticRecord/addOrRemoveQuestion', params)

// 质疑 - 获取写实记录质疑code的次数
export const getQuesCdNum = params => post('/shida-user-biz/TbRealisticRecord/getQuestionCodeNum', params)

// 质疑 - 添加回复
export const addQuesComets = params => post('/shida-user-biz/TbRealisticRecord/addReplyQuestion', params)

// 质疑 - 获取回复质疑
export const getQuesComets = params => post('/shida-user-biz/TbRealisticRecord/getReplyQuestion', params)

// 查看模板示例
export const getExample = params => post('/shida-user-biz/TbRealisticRecordTemplateInstance/getOneByThreeId', params)

// 回复1级及多级评论
export const cmt2cmt = params => post('/shida-user-biz/TbRealisticRecord/replyComments', params)
