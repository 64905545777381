// 写实记录：通用方法
let emojiList = require('@/assets/emoji/emoji.json')
let dynamicFn = {
	data() {
		return {
			imgSrc: this.imageSrc(),
			showEmoji: false,
			emojiList: emojiList,
			// 多层级评论数据暂存
			cmtMultiGrade: {
			  // 评论层级, 默认1即最外层评论
			  grade: 1,
			  // 当前操作的写实记录
			  item: {},
			  // 当前操作的写实记录下标
			  itemIdx: 0,
			  // 要回复的评论
			  el: {},
			  // 要回复的评论下标
			  elIdx: 0,
			  // 回复的回复的评论
			  cmt: {},
			  // 回复的回复的评论下标
			  cmtIdx: 0
			}
		}
	},
	methods: {
		/*
			格式化1级评论内容
			rec: 当前写实记录
			idx: 当前写实记录下标
			list: 请求返回的待处理的评论记录
			singleCmt: 添加单条评论时传入本参数
		*/
		process1stCommts(rec, idx, list, singleCmt) {
			let temp = []
			let dataResouce = []
			// 长度为0即初始加载, 在getList内调用
			if (list.length == 0 && singleCmt.length == 0) {
				dataResouce = rec.comments
			} else if (list.length != 0 && singleCmt.length == 0) {
				// 加载更多评论, 在getMore1stComments内调用
				dataResouce = list
			} else if (list.length == 0 && singleCmt.length == 1) {
				// 只处理1条评论，代替添加评论后刷新整个list的操作
				dataResouce = singleCmt
			}
			dataResouce.forEach(v => {
				let item = {}
				item = v.comments.split(',')
				item[3] = this.splitDate(item[3])
				item.push({
					// 初始化页面2级评论list
					list: [],
					// 已加载个数
					total: 0
				}, {
					cmtTotal: v.commentsNum,
					isShowMore: v.isShowMoreCommentsData
				})
				temp.push(item)
			})
			return temp
		},
		// 是否显示评论的加载更多按钮
		showLoadMoreCmt(item) {
			// 页面已显示数量 < 实际总条数
			return item.comments.length < item.commentsNum
		},
		// 是否显示2级评论
		show2thCmtZone(el) {
			if (Array.isArray(el)) {
				if(!el[7]){
					return false;
				}
				return el[7].cmtTotal != 0
			} else {
				return false
			}
		},
		// 是否显示2级评论的加载更多按钮
		showLoadMore2ndCmt(el) {
			if (Array.isArray(el)) {
				// 评论包含回复
				let cond1 = el[7].cmtTotal != 0
				// 页面已显示的回复 < 总回复条数
				let cond2 = el[6].list.length < el[6].total
				// 初始list为空时的状态
				let cond3 = el[6].list.length == 0 && el[6].total == 0
				if (cond3) {
					// 初始状态只检查是否满足条件1
					return cond1
				} else {
					// 否则检查条件2
					return cond2
				}
			} else {
				return false
			}
		},
		// 格式化2级评论内容：rec当前写实记录, idx当前写实记录下标, cmtItem当前评论, cmtIdx当前评论下标
		process2ndCommts(list) {
			let temp = []
			list.forEach(v => {
				let item = v.comments.split(',')
				item[3] = this.splitDate(item[3])
				temp.push(item)
			})
			return temp
		},
		// 获取更多1级评论
		getMore1stComments(item, idx) {
			this.seeMoreComments({
				id: item.id,
				// 页面已显示数量
				pageNum: item.comments.length == 0 ? 0 : item.comments.length + 1,
				pageSize: 3
			}, (res) => {
				let list = res.data.list
				if (list.length > 0) {
					// 拼接新的评论到页面
					this.dynamic[idx].comments = this.dynamic[idx].comments.concat(this.process1stCommts(item, idx, list, []))
				} else {
					this.$message.warning('已无更多评论')
				}
			})
		},
		// 获取更多2级评论: recItem当前写实记录, recIdx当前写实记录, item: 当前评论, idx: 当前评论的下标
		getMore2ndComments(recItem, recIdx, item, idx) {
			this.seeMoreComments({
				id: recItem.id,
				// 页面已显示数量
				pageNum: item[6].list.length == 0 ? 0 : item[6].list.length + 1,
				pageSize: 3,
				replyToUserId: item[5]
			}, (res) => {
				let list = res.data.list
				if (list.length == 0) {
					this.$message.warning('已无更多回复')
				} else {
					// 更新总数
					this.dynamic[recIdx].comments[idx][6].total = res.data.total
					// 找到当前写实记录 this.dynamic[recIdx]
					// 定位至当前点击的回复列表 this.dynamic[recIdx].comments[idx][6].list
					this.dynamic[recIdx].comments[idx][6].list = this.dynamic[recIdx].comments[idx][6].list.concat(this.process2ndCommts(list))
				}
			})
		},
		//  打开评论输入域, item当前写实记录, idx当前写实记录下标, el: 当前评论, elIdx: 当前评论的下标, grade: 当前评论的层级, cmt：当前回复，cmtIdx: 当前回复下标
		// grade：1 = 评论按钮/底部评论区, 2 = 评论的回复, 3 = 2的回复
		openComment(item, idx, el, elIdx, grade, cmt, cmtIdx) {
			this.cmtMultiGrade.grade = grade
			this.cmtMultiGrade.item = {}
			this.cmtMultiGrade.idx = -1
			this.cmtMultiGrade.el = {}
			this.cmtMultiGrade.elIdx = -1
			this.cmtMultiGrade.cmt = {}
			this.cmtMultiGrade.cmtIdx = -1
			if (grade == 1) {
				this.cmtMultiGrade.item = item
				this.cmtMultiGrade.idx = idx
			} else if (grade == 2) {
				this.cmtMultiGrade.item = item
				this.cmtMultiGrade.idx = idx
				this.cmtMultiGrade.el = el
				this.cmtMultiGrade.elIdx = elIdx
			} else if (grade == 3) {
				this.cmtMultiGrade.item = item
				this.cmtMultiGrade.idx = idx
				this.cmtMultiGrade.el = el
				this.cmtMultiGrade.elIdx = elIdx
				this.cmtMultiGrade.cmt = cmt
				this.cmtMultiGrade.cmtIdx = cmtIdx
			}
			this.handleComment(idx)
		},
		
		// 打开评论区
		handleComment(index) {
			this.dynamic.forEach(item => {
				item.isComments = false
			})
			this.dynamic[index].isComments = true
			let cmtMg = this.cmtMultiGrade
			this.commentPh = '添加评论'
			// 回复
			if (cmtMg.grade == 2) {
				this.commentPh = `回复给@${cmtMg.el[2]}：`
			} else if (cmtMg.grade == 3) {
				this.commentPh = `回复给@${cmtMg.cmt[2]}：`
			}
		},
		// 输入emoji
		inputEmoji(idx, index) {
			let textArea = document.querySelector(`#cometEditor${index}`)
			let curEmoji = emojiList[idx].char
			// https://blog.csdn.net/qq_40340478/article/details/106579577
			if (window.getSelection) {
			   textArea.focus()
			   textArea.setRangeText(curEmoji)
			   textArea.selectionStart += curEmoji.length
			} else if (document.selection) {
				el.focus()
				let sel = document.selection.createRange()
				sel.text = curEmoji
			}
			this.commentContent = textArea.value
		}
	}
}

export { dynamicFn }